import React from 'react';
import { Modal, Table, Button } from 'react-bootstrap';

const ViewStudentsModal = ({ show, onClose, students }) => {
    return (
        <Modal show={show} onHide={onClose} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>Assigned Students</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {students.length > 0 ? (
                    <Table bordered style={{height:"400px",overflow:"auto"}}>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                            </tr>
                        </thead>
                        <tbody>
                            {students.map((student) => (
                                <tr key={student._id}>
                                    <td>{student.fullName}</td>
                                    <td>{student.email}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                ) : (
                    <p>No students assigned to this assignment.</p>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ViewStudentsModal;
