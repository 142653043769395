import React, { useEffect, useState } from "react";
import { Button, Form, Image, InputGroup, Table } from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import nodataimage from "../../../assets/nodataimage.svg";
import { faFilter, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageHeading from "components/shared/PageHeading";
import CustomPagination from "components/shared/Pagination";
import ReasonModal from "components/modals/ReasonModal";
import { getAllAssignment } from "services/Teacher/Assignments.service";
import moment from "moment";
import { createSubmission } from "services/Student/Submission.services";

const Assignments = ({source=null,classId=null}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const initialPage = parseInt(searchParams.get("page"), 10) || 1;
  const initialLimit = parseInt(searchParams.get("limit"), 10) || 3;
  const initialSearchQuery = searchParams.get("search") || "";

  const [exercises, setExercises] = useState([]);
  const [searchQuery, setSearchQuery] = useState(initialSearchQuery);
  const [search, setSearch] = useState(initialSearchQuery);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [totalPages, setTotalPages] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [pendingStatusChange, setPendingStatusChange] = useState(null);

  const limit = initialLimit;

  const AllExercises = async (page = 1, limit = 2, search = "") => {
    setLoading(true);
    try {
      const response = await getAllAssignment(page, limit, search,source,classId);
      if (response.data && response.data.results) {
        setExercises(response.data.results?.assignments || []);
        setTotalPages(response.data.results.totalPages || 1);
      } else {
        setExercises([]);
        setTotalPages(1);
      }
    } catch (error) {
      console.error("Error fetching exercises:", error);
      setExercises([]);
      setTotalPages(1);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    AllExercises(currentPage, limit, searchQuery);
  }, [currentPage, limit, searchQuery]);

  useEffect(() => {
    const params = new URLSearchParams({
      page: currentPage,
      limit,
      search: searchQuery,
    });
    navigate({ search: `?${params.toString()}` });
  }, [currentPage, limit, searchQuery, navigate]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setSearchQuery(search);
    setCurrentPage(1);
  };

  const handleAction = (status, id,subId) => {
    switch (status) {
      case "pending":
        handlePendingAction(id);
        break;
      case "in-progress":
        handleInProgressAction(subId);
        break;
      case "submitted":
        handleSubmittedAction(subId);
        break;
      case "marked":
        handleMarkedAction(subId);
        break;
      case "revise-needed":
          handleReviseAction(id,subId);
          break;
      default:
        console.log("Unknown action");
    }
  };

  const handlePendingAction = async (id) => {
    console.log(`Handling pending action for assignment ${id}`);
    try {
        const response = await createSubmission({
            assignmentId:id
          });
          console.log("response submission",response.data);
          navigate(`/assignments/attempt/${response.data?.result?._id}`);
    } catch (error) {
        console.error(error)
    }
  };

  const handleInProgressAction = async (id) => {
    console.log(`Handling in-progress action for assignment ${id}`);
    navigate(`/assignments/attempt/${id}`);
  };

  const handleReviseAction = async (id,subId) => {
    console.log(`Handling revise action for submission ${id}`);
    const response = await createSubmission({
      assignmentId:id,
      submissionId:subId
    },"revise-needed");
    navigate(`/assignments/attempt/${subId}`);
  };

  const handleSubmittedAction = (id) => {
    console.log(`Handling submitted action for assignment ${id}`);
    navigate(`/assignments/submission/${id}`);
  };

  const handleMarkedAction = (id) => {
    console.log(`Handling marked action for assignment ${id}`);
    navigate(`/assignments/submission/marked/${id}`);

  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getLevelBackgroundColor = (level) => {
    switch (level) {
      case "pending":
        return "#C4C4C4";
      case "in-progress":
        return "#4DA9F4";
      case "submitted":
        return "#0FE133";
      case "marked":
        return "#EB3232";
      default:
        return "#B0BEC5";
    }
  };

  const getButtonLabel = (status) => {
    switch (status) {
      case "pending":
      case "in-progress":
        return "Attempt";
      case "submitted":
        return "View Submission";
      case "marked":
        return "View Remarks";
      case "revise-needed":
        return "Re-Attempt";
      default:
        return "Take Action";
    }
  };

  return (
    <>
      <div className="d-block d-lg-flex justify-content-between">
        <div>
          <PageHeading heading={"Assignments"} />
        </div>
        <div className="d-block d-lg-flex gap-3 justify-content-between">
          <div>
            <Button
              className="px-4 py-2 w-100 w-lg-auto my-lg-0 my-2"
              style={{
                color: "#858585",
                borderRadius: "30px",
                background: "white",
                border: "2px solid #858585",
                fontWeight: 600,
              }}
            >
              Sort By
              <FontAwesomeIcon icon={faFilter} className="ms-2" />
            </Button>
          </div>
          <div>
            <Form.Group
              className="mb-3 position-relative"
              controlId="formBasicPassword"
            >
              <InputGroup className="mb-3">
                <Form.Control
                  type={"text"}
                  placeholder="Search Here..."
                  className="px-3 py-2 border-right-0"
                  value={search}
                  onChange={handleSearchChange}
                  style={{
                    borderTopLeftRadius: "30px",
                    borderBottomLeftRadius: "30px",
                    borderColor: "#ea4f50",
                    borderRight: "0",
                  }}
                />
                <InputGroup.Text
                  id="basic-addon2"
                  className="bg-white "
                  style={{
                    cursor: "pointer",
                    borderTopRightRadius: "30px",
                    borderBottomRightRadius: "30px",
                    borderColor: "#ea4f50",
                    borderLeft: "0",
                  }}
                  onClick={handleSearchSubmit}
                >
                  <FontAwesomeIcon icon={faSearch} />
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <Table responsive="sm" style={{ overflow: "auto" }}>
          <thead>
            <tr>
              <th style={{ color: "#303972" }}>Assignment Name</th>
              <th style={{ color: "#303972" }}>Subject</th>
              <th style={{ color: "#303972" }}>Deadline</th>
              <th style={{ color: "#303972" }}>Status</th>
              <th style={{ color: "#303972" }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              [...Array(limit)].map((_, index) => (
                <tr key={index}>
                  <td className="py-3">
                    <div
                      style={{
                        backgroundColor: "#e0e0e0",
                        height: "20px",
                        width: "80%",
                        borderRadius: "4px",
                      }}
                    ></div>
                  </td>
                  <td className="py-3">
                    <div
                      style={{
                        backgroundColor: "#e0e0e0",
                        height: "20px",
                        width: "40%",
                        borderRadius: "4px",
                      }}
                    ></div>
                  </td>
                  <td className="py-3">
                    <div
                      style={{
                        backgroundColor: "#e0e0e0",
                        height: "20px",
                        width: "40%",
                        borderRadius: "4px",
                      }}
                    ></div>
                  </td>
                  <td className="py-3">
                    <div
                      style={{
                        backgroundColor: "#e0e0e0",
                        height: "20px",
                        width: "40%",
                        borderRadius: "4px",
                      }}
                    ></div>
                  </td>
                  <td className="py-3">
                    <div
                      style={{
                        backgroundColor: "#e0e0e0",
                        height: "20px",
                        width: "40%",
                        borderRadius: "4px",
                      }}
                    ></div>
                  </td>
                </tr>
              ))
            ) : exercises.length === 0 ? (
              <tr>
                <td colSpan="5" className="text-center py-3">
                  <Image src={nodataimage} />
                  <p className="pt-2 text-secondary fw-bold fs-5">No assignments found</p>
                </td>
              </tr>
            ) : (
              exercises.map((exercise, index) => (
                <tr key={exercise._id}>
                  <td className="py-3">
                    <div
                    //   to={`attempt/${exercise?.exercise?._id}`}
                      style={{
                        color: "#303972",
                        fontWeight: 700,
                        textDecoration: "none",
                      }}
                    >
                      {exercise.title}
                    </div>
                  </td>
                  <td className="py-3" style={{ color: "#A098AE" }}>
                    {exercise?.exercise?.subject?.title}
                  </td>
                  <td className="py-3" style={{ color: "#A098AE" }}>
                    {moment(exercise?.endDate).format("MMM D, YYYY")}
                  </td>
                  <td className="py-3" style={{ color: "#A098AE" }}>
                    <span
                      style={{
                        display: "inline-block",
                        padding: "3px 10px",
                        borderRadius: "30px",
                        backgroundColor: getLevelBackgroundColor(
                          exercise?.submissionStatus
                        ),
                        color: "#fff",
                        fontSize: "14px",
                      }}
                    >
                      {exercise?.submissionStatus.toUpperCase()} 
                      
                    </span>
                  </td>
                  <td className="py-3">
                    <Button
                      variant="primary"
                      className="button-style border-0 py-1 px-3"
                      style={{
                        fontSize: "14px"
                      }}
                      onClick={() =>
                        handleAction(exercise?.submissionStatus, exercise?._id,exercise?.submissionId)
                      }
                    >
                      {getButtonLabel(exercise?.submissionStatus)}
                    </Button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </div>
      <CustomPagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </>
  );
};

export default Assignments;
