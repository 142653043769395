import PageHeading from 'components/shared/PageHeading'
import React, { useState } from 'react'
import { Card, Col, Image, Row } from 'react-bootstrap'
import contactimg from "../../../assets/contactother.png"
import { faNewspaper, faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
const Contact = () => {
  const [title, setTitle] = useState("")
  const [comments, setComments] = useState("");
  const navigate= useNavigate();

  const cardData = [
    {
        title: 'Submit a Query',
        description: 'Tell us about your issue, and we will get back to you as soon as possible.',
        icon: faPenToSquare,
        backgroundColor: "#FFF3F3",
        Color: "#AE3E3E",
        navigate: "create"
    },
    {
        title: 'My Queries',
        description: 'View and manage your submitted queries here.',
        icon: faNewspaper,
        backgroundColor: "#FFF3F3",
        Color: "#AE3E3E",
        navigate: "my-tickets"
    }
];

  return (
    <>
      <PageHeading heading={"Help Center"} />
      <Row className='justify-content-start my-4'>
        <Col xs={12} lg={6} xl={7} className=''>
          <Card className="p-3 border-0 shadow mt-3 pb-3 px-4">
            <PageHeading heading={"Get In Touch With Us"} />
            <div className='my-2' style={{ width: "100px", background: "#e25859", borderRadius: "60px", height: "6px" }} />
            <p className='text-muted mt-2'>Need Help with Something? Report Your Issue or Ask a Question</p>
            <p className='text-muted mb-0'>Our Support Team is Here to Assist You Every Step of the Way. Simply Submit a Query, and We’ll Work on a Fast Resolution!</p>
            <div className='mt-4 mx-auto'>
            <Row className='justify-content-center'>
                {cardData.map((card, index) => (
                    <Col xs={12} lg={6} md={12} key={index}>
                        <Card className='border-0 shadow p-3 my-3' onClick={() => navigate(card.navigate)} style={{ background: card.backgroundColor, color: card.Color, cursor: "pointer",minHeight:"150px"}}>
                            <div className="d-flex gap-3">
                                <div className='icon-container d-flex align-items-center' style={{ width: "100px" }}>
                                    <FontAwesomeIcon icon={card.icon} style={{ fontSize: card?.iconSize || "60px", margin: "auto", width: '60px'}} />
                                </div>
                                <div className="vr" style={{ minHeight: "100%" }}></div>
                                <div>
                                    <h4 style={{ fontWeight: "700" }} className='mb-1'>{card.title}</h4>
                                    <p className='text-muted m-0' style={{ minHeight: "80px" }}>{card.description}</p>
                                </div>
                            </div>
                        </Card>
                    </Col>
                ))}
            </Row>
            </div>
          </Card>
        </Col>
        <Col xs={12} lg={6} xl={5}>
          <div>
            <Image className='contact-img' src={contactimg} width={"100%"} />
          </div>
        </Col>
      </Row>
    </>
  )
}

export default Contact