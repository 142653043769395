import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import SelectReact from "react-select";
import { getAllTeachersAndStudents } from "services/Teacher/User.service";
import { AddStudentsToGroup } from "services/Teacher/Class.service";
import { toast } from "react-toastify";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AddGroupStudentsModal = ({ show, handleClose, fetchClasses, groupId }) => {
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [students, setStudents] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const schoolId = JSON.parse(localStorage.getItem("user"))?.school;

  useEffect(() => {
    console.log("groupId",groupId);
    const fetchStudents = async () => {
      try {
        const response = await getAllTeachersAndStudents(schoolId, "student");
        setStudents(response.data.users || []);
      } catch (error) {
        console.error("Error fetching students:", error);
      }
    };

    if (schoolId) {
      fetchStudents();
    }
  }, [schoolId,show]);

  const handleSubmit = async () => {
    setIsSubmitting(true); // Disable button
    const payload = {
      groupId:groupId,
      studentIds: selectedStudents.map((student) => student.value),
    };

    const toastId = toast.loading("Creating class...");

    try {
      const response = await AddStudentsToGroup(payload);
      console.log("Class created successfully:", response);
      handleClose();
      fetchClasses();
      toast.update(toastId, {
        render: "Students added to class successfully!",
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
      setSelectedStudents([]);
    } catch (error) {
      console.error("Error adding students:", error);
      toast.update(toastId, {
        render: "Error adding students. Try again later",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered size="lg" backdrop="static">
      <Modal.Header className="border-0" closeButton />
      <Modal.Body >
       <div className="w-100 text-center">
       <FontAwesomeIcon
          className="p-3"
          icon={faAdd}
          style={{
            fontSize: "40px",
            color: "red",
            border: "3px solid red",
            borderRadius: "60px",
            width: "40px",
            height: "40px",
          }}
        />
        <h3 className="mt-1">Add Students</h3>
       </div>
        <div className="my-2">
          <Form.Label>Select Students to Add</Form.Label>
          <SelectReact
            isMulti
            options={students.map((student) => ({
              value: student._id,
              label: `${student.fullName} - ${student.email}`,
            }))}
            value={selectedStudents}
            onChange={(selected) => setSelectedStudents(selected)}
            aria-label="Select multiple students"
            placeholder="Select students"
            className="multi-select"
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-between pt-0">
        <Button
          variant="secondary"
          className="mt-1 px-4 button-style py-2 border-0"
          style={{
            background: "linear-gradient(270deg, #272727 0%, #4B4B4B 100%)",
          }}
          onClick={handleClose}
          disabled={isSubmitting}
        >
          Back
        </Button>
        <Button
          variant="primary"
          className="mt-1 px-4 button-style py-2 border-0"
          onClick={handleSubmit}
          disabled={isSubmitting} // Disable during submission
        >
          Add to Group
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddGroupStudentsModal;
