import { googleLogout } from '@react-oauth/google';
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthVerify, GetUserRoles } from 'utils/auth.utils';

const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
    const [isVerified, setIsVerified] = useState(false);
    const [loading, setLoading] = useState(true);
    const [userRoles, setUserRoles] = useState([]);
    const [user, setUser] = useState(null);

    const navigate = useNavigate();

    const verifyAuth = () => {
        setLoading(true);
        const loggedInUser = JSON.parse(localStorage?.getItem("user"));

        if (loggedInUser && loggedInUser?.token) {
            setIsVerified(true);
            setUser(loggedInUser); // Set user from local storage
            setUserRoles(loggedInUser?.role ? loggedInUser?.role : []); // Adjust role handling if necessary
        } else {
            setIsVerified(false);
            setUser(null);
            setUserRoles([]);
        }
        setLoading(false);
    };

    const logout = () => {
        localStorage.removeItem("user");
        googleLogout();
        setIsVerified(false);
        setUser(null);
        setUserRoles([]);
        navigate('/login');
    };

    useEffect(() => {
        verifyAuth();
    }, []);

    return (
        <AuthContext.Provider value={{ isVerified, userRoles,user, verifyAuth, logout, loading, setLoading }}>
            {children}
        </AuthContext.Provider>
    );
};
