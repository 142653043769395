import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Contact from './Contact'
import CreateTicket from './CreateTicket'
import MyTickets from './MyTickets'
import TicketDetail from './TicketDetail'

const ContactRoutes = () => {
    return (
        <>
            <Routes>
            <Route path='/' element={<Contact />} />
            <Route path='/create' element={<CreateTicket />} />
            <Route path='/my-tickets' element={<MyTickets />} />
            <Route path='/my-tickets/:ticketId' element={<TicketDetail />} />
            </Routes>
        </>
    )
}

export default ContactRoutes;