import CustomPagination from "components/shared/Pagination";
import React, { useEffect, useState, useCallback } from "react";
import { Modal, Button, Table, Row, Col, FormControl, InputGroup } from "react-bootstrap";
import { getAllWords } from "services/Teacher/Vocabulary.service";
import { debounce } from "lodash"; 

const AllWordsModal = ({ show, handleClose, addWordToVocabulary }) => {
  const [words, setWords] = useState([]);
  const [selectedWords, setSelectedWords] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchQuery, setSearchQuery] = useState(""); // Search query state

  const fetchWords = useCallback(
    debounce(async (page, limit, searchQuery) => {
      try {
        const response = await getAllWords(page, limit, searchQuery);
        setWords(response.data.results.words);
        setTotalPages(response.data.results.totalPages);
      } catch (error) {
        console.error("Error fetching words:", error);
      }
    }, 300),
    []
  );

  // Fetch words when modal is opened or currentPage/searchQuery changes
  useEffect(() => {
    if (show) {
      fetchWords(currentPage, limit, searchQuery);
    }
  }, [show, currentPage, limit, searchQuery, fetchWords]);

  const handleSelectWord = (wordId) => {
    setSelectedWords((prev) =>
      prev.includes(wordId)
        ? prev.filter((id) => id !== wordId)
        : [...prev, wordId]
    );
  };

  const handleAddWords = () => {
    addWordToVocabulary(selectedWords);
    handleClose();
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value); // Update the search query
    setCurrentPage(1); // Reset to the first page on search
  };

  return (
    <Modal show={show} onHide={handleClose} centered size={"lg"}>
      <Modal.Header closeButton>
        <Modal.Title>Add Words to Study Set</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h5 className="mb-0">Available Words</h5>
          {/* Search Input */}
          <InputGroup style={{ maxWidth: "300px" }}>
            <FormControl
              placeholder="Search words..."
              value={searchQuery}
              onChange={handleSearchChange} // Trigger search on change
            />
          </InputGroup>
        </div>
        <div className="custom-scrollbar" style={{ maxHeight: "500px", overflow: "auto" }}>
          <Table bordered hover responsive className="h-50">
            <thead>
              <tr>
                <th>Select</th>
                <th>Title</th>
                <th>Meaning</th>
              </tr>
            </thead>
            <tbody>
              {words.map((word) => (
                <tr
                  key={word._id}
                  onClick={() => handleSelectWord(word._id)}
                  style={{
                    cursor: "pointer",
                    backgroundColor: selectedWords.includes(word._id)
                      ? "#e9ecef"
                      : "white",
                  }}
                >
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedWords.includes(word._id)}
                      onChange={() => handleSelectWord(word._id)}
                    />
                  </td>
                  <td>{word.title}</td>
                  <td>{word.meaning.join(", ")}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Row className="w-100">
          <Col xs={12} className="d-flex justify-content-end ">
            <CustomPagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </Col>
        </Row>
        <div className="mt-2">
          <Button
            variant="secondary"
            style={{
              background: "linear-gradient(270deg, #272727 0%, #4B4B4B 100%)",
            }}
            className="button-style mx-2"
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            variant="primary"
            className="button-style"
            onClick={handleAddWords}
          >
            Add Selected Words
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default AllWordsModal;
