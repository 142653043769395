import React, { useState } from "react";
import {
  Modal,
  Button,
  Form,
  Spinner,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { toast } from "react-toastify";
import {
  importStudentSheet,
  createBulkStudents,
} from "services/Teacher/ClassesAndGroups.service";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileDownload,
  faPlusCircle,
  faQuestionCircle,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";

const ImportSheetModal = ({ show, handleClose, handleShowCredentials,fetchUsers, classId, setSummaryData }) => {
  const [file, setFile] = useState(null);
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleImport = async () => {
    if (!file) {
      toast.error("Please select a CSV or XLSX file.");
      return;
    }

    const formData = new FormData();
    formData.append("attachment", file);

    const toastId = toast.loading("Importing students...");
    setLoading(true);
    try {
      const response = await importStudentSheet(formData);
      setStudents(response.data.results || []);
      toast.update(toastId, {
        render: "Students imported successfully!",
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } catch (error) {
      console.error("Error importing students:", error);
      toast.update(toastId, {
        render: "Error importing students",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCreateStudents = async () => {
    if (students.length === 0) {
      toast.error("No students to create. Please import a sheet first.");
      return;
    }

    const formattedStudents = students.map((student) => ({
      ...student,
      classId,
      phone: student.phone ? Number(student.phone) : "",
    }));

    const toastId = toast.loading("Creating students...");
    setLoading(true);
    try {
       const response = await createBulkStudents({ students: formattedStudents });
      toast.update(toastId, {
        render: "Students created successfully!",
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
      handleCloseModal();
      console.log("res",response.data.result);
      setSummaryData(response?.data?.result);
      handleShowCredentials();
      fetchUsers();
    } catch (error) {
      console.error("Error creating students:", error);
      toast.update(toastId, {
        render: "Error creating students",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteRow = (rowIndex) => {
    const updatedStudents = students.filter((_, index) => index !== rowIndex);
    setStudents(updatedStudents);
  };

  const handleAddRow = (rowIndex) => {
    const newStudent = { fullName: "", email: "", phone: "", password: "" };
    const updatedStudents = [...students];
    updatedStudents.splice(rowIndex + 1, 0, newStudent);
    setStudents(updatedStudents);
  };

  const actionCellRenderer = (params) => {
    const handleDelete = () => {
      handleDeleteRow(params.node.rowIndex);
    };

    const handleAdd = () => {
      handleAddRow(params.node.rowIndex);
    };

    return (
      <div className="d-flex align-items-center justify-content-center gap-2">
        <Button onClick={handleDelete} className="bg-dark py-1 border-0Z">
          <FontAwesomeIcon
            icon={faTrashAlt}
            style={{ cursor: "pointer", color: "red" }}
          />
        </Button>
        <Button onClick={handleAdd} className="bg-dark py-1 border-0">
          <FontAwesomeIcon
            icon={faPlusCircle}
            style={{ cursor: "pointer", color: "white" }}
          />
        </Button>
      </div>
    );
  };

  const columns = [
    {
      headerName: "Full Name",
      field: "fullName",
      editable: true,
      cellEditorParams: { required: true },
    },
    {
      headerName: "Email",
      field: "email",
      editable: true,
      cellEditorParams: { required: true },
    },
    { headerName: "Phone", field: "phone", editable: true },
    {
      headerName: "Password",
      field: "password",
      editable: true,
      cellEditorParams: { required: true, minLength: 8, maxLength: 20 },
    },
    {
      headerName: "Actions",
      field: "actions",
      flex: 1,
      cellRenderer: actionCellRenderer,
    },
  ];

  const validateData = () => {
    const emailSet = new Set();
    for (const student of students) {
      if (!student.fullName || !student.email || !student.password) {
        toast.error(
          "Full Name, Email, and Password are required for each student."
        );
        return false;
      }
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(student.email)) {
        toast.error("Invalid email format for " + student.fullName);
        return false;
      }
      if (student.password.length < 8 || student.password.length > 20) {
        toast.error(
          "Password must be between 8 and 20 characters for " + student.fullName
        );
        return false;
      }
      if (student.phone && isNaN(student.phone)) {
        toast.error("Phone must be a valid number for " + student.fullName);
        return false;
      }
      if (emailSet.has(student.email)) {
        toast.error("Duplicate email found: " + student.email);
        return false;
      }
      emailSet.add(student.email);
    }
    return true;
  };

  const handleCloseModal = () => {
    setFile(null);
    setStudents([]);
    handleClose();
  };

  const instructionsTooltip = (
    <Tooltip className="mytooltip" id="file-upload-tooltip">
      <ul style={{ paddingLeft: "20px", margin: 0, textAlign: "left" }}>
        <li>Password must be between 8 and 20 characters.</li>
        <li>Existing students in the class will be ignored.</li>
        <li>Full Name, Email, and Password are required for each student.</li>
        <li>Phone is optional but must be in number format if provided.</li>
      </ul>
    </Tooltip>
  );

  return (
    <Modal
      show={show}
      onHide={handleCloseModal}
      centered
      size={"lg"}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Import Students</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className=" ">
          <Form.Group controlId="formFile" className="">
            <Form.Label>Upload CSV or XLSX file</Form.Label>
            <div className="d-flex align-items-center gap-2 justify-content-between ">
              <div className="d-flex align-items-center gap-2 ">
                <Form.Control
                  className="w-75"
                  type="file"
                  onChange={handleFileChange}
                />
                <Button
                  variant="primary"
                  onClick={handleImport}
                  disabled={loading}
                  className="button-30 border-0 bg-dark"
                >
                  {loading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Import Sheet"
                  )}
                </Button>
              </div>
              <div className="d-flex align-items-center gap-2 ">
                <Button
                  variant="primary"
                  disabled={loading}
                  className="button-62 border-0 bg-dark d-flex align-items-center gap-2"
                  href="/uploads/students.xlsx" // Change this to the correct path
                  download
                >
                  <FontAwesomeIcon icon={faFileDownload} />
                  <span>Download Sample Sheet</span>
                </Button>
              </div>
            </div>
          </Form.Group>
        </Form>
        {students.length > 0 && (
          <>
            <div className="d-flex align-items-center gap-1 mt-4">
              <h5 className="mb-1">Instructions</h5>
              <OverlayTrigger placement="right" overlay={instructionsTooltip}>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  className="p-0 ms-2"
                  style={{ cursor: "pointer" }}
                />
              </OverlayTrigger>
            </div>
            <div
              className="ag-theme-alpine mt-4"
              style={{ height: 300, width: "100%" }}
            >
              <AgGridReact
                rowData={students}
                columnDefs={columns}
                defaultColDef={{ flex: 1 }}
                animateRows={true}
                enableCellTextSelection={true}
                onCellValueChanged={(event) => {
                  const updatedStudents = [...students];
                  updatedStudents[event.rowIndex] = event.data;
                  setStudents(updatedStudents);
                }}
              />
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="button-62"
          variant="secondary"
          onClick={handleCloseModal}
          disabled={loading}
        >
          Close
        </Button>
        <Button
          className="button-62"
          variant="primary"
          onClick={() => {
            if (validateData()) {
              handleCreateStudents();
            }
          }}
          disabled={loading || students.length === 0}
        >
          {loading ? (
            <Spinner animation="border" size="sm" />
          ) : (
            "Create Students"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImportSheetModal;
