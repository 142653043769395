import React from 'react';

const CapitalizeFirst = ({ text,bold,className="" }) => {
  const capitalizeFirstLetter = (str) => {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1);
  };

  return <span className={className}>{capitalizeFirstLetter(text)}</span>;
};

export default CapitalizeFirst;
