import { GetApiData } from "utils/http-client";


export const AddUsers = async (data) => {
    return await GetApiData(`/school/addUser`, 'POST', data, true);
};

export const addStudentToClass = async (data) => {
    return await GetApiData(`/class/students/single`, 'POST', data, true);
};
