import { GetApiData } from "../../utils/http-client";

export const LoginForm = function (data) {
    return GetApiData(`/user/login`, 'POST', data, true);
}
export const SignupForm = function (data) {
    return GetApiData(`/user/signup`, 'POST', data, true);
}

export const GoogleAuthLogin = function (data) {
    return GetApiData(`/user/auth/google/login`, 'POST', data, true);
}

export const GoogleAuthSignup = function (data) {
    return GetApiData(`/user/auth/google/signup`, 'POST', data, true);
}

export const getClassesByCode = function (code,role) {
    return GetApiData(`/class/all/${role}/${code}`, 'GET', null, true);
}

export const UpdateSchoolAndClass = function (data) {
    return GetApiData(`/user/updateSchoolInfo`, 'PUT', data, true);
}
