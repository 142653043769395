import React, { useState } from "react";
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { TypeAnimation } from "react-type-animation";
import { getClassesByCode, UpdateSchoolAndClass } from "services/authentication/authentication.service";
import { useAuth } from "utils/AuthContext";
import logo from "../../assets/logo.svg";
import leftimage from "../../assets/login.jpg";
import AuthenticationHeader from "components/shared/AuthenticationHeader";

const Verification = () => {
  const { user, verifyAuth } = useAuth(); // Get user and verifyAuth from context
  const [formData, setFormData] = useState({
    schoolCode: "",
    classId: "",
  });
  const [classes, setClasses] = useState([]); // To hold the list of classes
  const [loading, setLoading] = useState(false); // To manage form submission state
  const navigate = useNavigate();

  // Update form field values
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Fetch classes for the school (student role only)
  const handleFetchClasses = async () => {
    if (!formData.schoolCode) {
      toast.error("Please enter a valid school code");
      return;
    }
    setLoading(true);
    try {
      const response = await getClassesByCode(formData.schoolCode, "student");
      setClasses(response.data.results);
      toast.success("Classes loaded successfully");
    } catch (err) {
      console.error("Error:", err.response?.data?.error);
      toast.error(err.response?.data?.error || "Failed to fetch classes");
    } finally {
      setLoading(false);
    }
  };

  // Submit school and class details
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation: Ensure fields are filled
    if (!formData.schoolCode) {
      toast.error("School code is required");
      return;
    }
    if (user?.role === "user" && !formData.classId) {
      toast.error("Class is required for students");
      return;
    }

    setLoading(true);
    try {
      const payload = {
        schoolCode: formData.schoolCode,
        classId: user?.role === "user" ? formData.classId : undefined,
      };
      const response= await UpdateSchoolAndClass(payload); // API call to update school and class
      localStorage.setItem('user', JSON.stringify(response.data.user));
      toast.success("Details updated successfully!");
      verifyAuth(); // Refresh user data in AuthContext
      navigate("/dashboard"); // Navigate to the dashboard
    } catch (err) {
      console.error("Error:", err.response?.data?.error);
      toast.error(err.response?.data?.error || "Failed to update details");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container fluid style={{ minHeight: '100vh' }}>
      <Row className="justify-content-center">
      <Col xs={12} md={6} className='p-0 d-none d-md-block position-relative'>
                    <Image
                        src={leftimage}
                        alt='Signup Illustration'
                        className='w-100'
                        style={{ height: '100vh', objectFit: 'cover' }}
                    />
                    <div className='overlay position-absolute w-100 h-100' style={{ top: 0, left: 0, backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
                        <div className='text-overlay position-absolute d-flex flex-column justify-content-center align-items-start' style={{ height: '100vh', left: '5%', top: 0 }}>
                            <div>
                                <div className=" text-white  mb-2 text-uppercase">
                                    <h1 className='mb-3' style={{ fontSize: "50px", fontWeight: "800", color: "#ed4e4f" }}>Fun meets<br /> knowledge!</h1>
                                </div>
                            </div>

                            <TypeAnimation
                                sequence={[
                                    'Learn While Playing Games!',
                                    1000,
                                    'Explore Animal Facts in Fun Quizzes',
                                    1000,
                                    'Test Your Knowledge with Challenges',
                                    1000,
                                    'Discover New Things with Every Level',
                                    1000
                                ]}
                                wrapper="div"
                                speed={50}
                                style={{ fontSize: '32px', display: 'inline-block', fontWeight: '600', color: 'white' }}
                                repeat={Infinity}
                            />
                        </div>
                    </div>

                </Col>
        <Col xs={12} md={6} className='d-flex align-items-center justify-content-center p-4 authentication-form'>
        <Col xs={12} lg={7}>
        <AuthenticationHeader
                            image={logo}
                            text={'Verify your school details'}
                        />
          <p className="text-center text-muted">
            Enter your school code {user?.role === "user" && "and select your class"} to proceed.
          </p>
          <Form onSubmit={handleSubmit}>
            {/* School Code */}
            <Form.Group controlId="formSchoolCode" className="mb-3">
              <Form.Label>School Code</Form.Label>
              <Form.Control
                type="text"
                name="schoolCode"
                placeholder="Enter school code"
                value={formData.schoolCode}
                onChange={handleChange}
                required
              />
            </Form.Group>

            {/* Fetch classes button for students */}
            {user?.role === "user" && (
              <>
                <Button
                  className="mb-3 button-62"
                  onClick={handleFetchClasses}
                  disabled={!formData.schoolCode || loading}
                >
                  {loading ? "Fetching Classes..." : "Fetch Classes"}
                </Button>

                {/* Class selection dropdown */}
                <Form.Group controlId="formClassId" className="mb-3">
                  <Form.Label>Class</Form.Label>
                  <Form.Control
                    as="select"
                    name="classId"
                    value={formData.classId}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select a class</option>
                    {classes.map((cls) => (
                      <option key={cls._id} value={cls._id}>
                        {cls.title}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </>
            )}

            {/* Submit button */}
            <Button
              type="submit"
              className="w-100 button-62"
              disabled={loading}
              style={{ fontSize: "15px" }}
            >
              {loading ? "Submitting..." : "Submit"}
            </Button>
          </Form>
        </Col>
         
        </Col>
      </Row>
    </Container>
  );
};

export default Verification;
