// import { faTrash } from '@fortawesome/free-solid-svg-icons';
import PageHeading from 'components/shared/PageHeading';
import deleteIcon from '../../../assets/delete.svg'
import React, { useState } from 'react';
import { Col, Container, Form, Image, Row } from 'react-bootstrap';

const VocabularyListReports = ({ studySetDetail }) => {
    const [selectedItems, setSelectedItems] = useState({});



    const handleCheckboxChange = (id) => {
        setSelectedItems(prevSelectedItems => ({
            ...prevSelectedItems,
            [id]: !prevSelectedItems[id]
        }));
    };

    return (
        <>
      
            <Container fluid>
                    <ol>
                        {studySetDetail?.map((report, reportIndex) => (
                <div className='mt-4 shadow p-2 '>

                            <Row key={reportIndex} className='my-3'>
                                <Col xs={1} className='pe-0'>
                                    <div className='d-flex justify-content-start mt-3'>
                                        <Form.Check
                                            type={"checkbox"}
                                            id={`default-checkbox-${reportIndex}`}
                                            label={``}
                                            checked={!!selectedItems[report?.id]}
                                            onChange={() => handleCheckboxChange(report?.id)}
                                        />
                                    </div>
                                </Col>
                                <Col xs={11} className='ps-0'>
                                    <li className='border-0 report-listing'>
                                        <div className="d-flex justify-content-between ">
                                            <div>
                                                <PageHeading heading={report?.title} />
                                            </div>
                                            <div className='text-center' role="button"  >
                                                <span className='px-2 py-2 text-danger' ><Image src={deleteIcon} /></span>
                                            </div>
                                        </div>
                                        <ul className='nested-report-listing ps-3'>
                                            {report?.meaning.map((item, index) => (
                                                <li
                                                    key={`${report?.id}-${index}`}
                                                    style={{
                                                        color: "#303972",
                                                        fontSize: "16px",
                                                        fontWeight: 400,
                                                        lineHeight: "24px",
                                                    }}
                                                >
                                                    {item}
                                                </li>
                                            ))}
                                        </ul>
                                        <div>
                                            <p className='mt-1' style={{
                                                color: "#303972",
                                                fontSize: "18px",
                                                fontWeight: 400,
                                                lineHeight: "24px",
                                            }}>
                                                {report?.sentence}
                                            </p>
                                        </div>

                                    </li>
                                </Col>
                            </Row>
                </div>

                        ))}
                    </ol>

            </Container>

        </>
    );
};

export default VocabularyListReports;
