import React from 'react'
import { Image } from 'react-bootstrap'
import thinkfirst from "../../assets/thinkfirst.svg"
const AuthenticationHeader = ({ image, heading, text }) => {
    return (
        <>
            <div>
                {image ?
                    <div className=' mb-2' style={{ width: "100px", height: "100px", margin: "auto" }}>
                        <Image
                            src={image}
                            alt="logo"
                            className='w-60 w-lg-100'
                        />

                    </div>
                    : ""}
                <div style={{ width: "250px" }} className='mx-auto mb-2'>
                    <Image
                        src={thinkfirst}
                        alt="logo"
                        className=' w-100'
                    />
                </div>
                {heading || text ?
                    <div className="text-center heading   text-uppercase">
                        <h1>{heading}</h1>
                        <p>{text}</p>
                    </div>
                    : ""}
            </div>
        </>
    )
}

export default AuthenticationHeader