import React, { useState, useEffect } from "react";
import { Table, Button, Container } from "react-bootstrap";  // or any other UI library you prefer
import axios from "axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { GetMyTickets } from "services/Contact/HelpCenter.service";
import BreadCrumbs from "components/shared/BreadCrumbs";
import CapitalizeFirst from "components/shared/CapitalizeFirst";

const MyTickets = () => {
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const breadcrumbItems = [
    { label: "Help Center", href: "/contact-us" },
    {
        label: "My Queries",
        active: true,
      },
  ];

  // Fetch tickets on component mount
  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const response = await GetMyTickets(); // Adjust the endpoint as needed
        setTickets(response.data.results);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error("Failed to load tickets.");
      }
    };

    fetchTickets();
  }, []);

  return (
    <Container fluid>
        <div className="mb-3">
        <BreadCrumbs items={breadcrumbItems} />
      </div>
      <h3>User Queries</h3>

      {loading ? (
        <p>Loading queries...</p>
      ) : (
        <Table style={{height:"500px"}} bordered hover>
          <thead>
            <tr>
              <th>Query ID</th>
              <th>Subject</th>
              <th>Type</th>
              <th>Status</th>
              <th>Created At</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {tickets.length > 0 ? (
              tickets.map((ticket) => (
                <tr key={ticket._id}>
                  <td>{ticket._id}</td>
                  <td><span style={{textOverflow:"ellipsis"}}>{ticket.subject}</span></td>
                  <td><CapitalizeFirst text={ticket.type} /></td>
                  <td>
                    <span className={`status-badge status-${ticket.status}`}>
                      <CapitalizeFirst text={ticket.status} />
                    </span>
                  </td>
                  <td>{new Date(ticket.createdAt).toLocaleString()}</td>
                  <td>
                    <Link to={`/contact-us/my-tickets/${ticket._id}`}>
                      <Button className="button-style border-0 px-3" variant="primary">View</Button>
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6">No tickets found.</td>
              </tr>
            )}
          </tbody>
        </Table>
      )}
    </Container>
  );
};

export default MyTickets;
