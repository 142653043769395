import { GetApiData } from "utils/http-client";


export const CreateTicketApi = async (data) => {
    return await GetApiData(`/ticket`, 'POST', data, true);
};

export const GetMyTickets = async (data) => {
    return await GetApiData(`/ticket/user-tickets`, 'GET', data, true);
};

export const GetAllTickets = async () => {
    return await GetApiData(`/ticket`, 'GET', null, true);
};

export const GetTicketDetail = async (id) => {
    return await GetApiData(`/ticket/${id}`, 'GET', null, true);
};


export const UpdateTicketStatus = async (id,data) => {
    return await GetApiData(`/ticket/${id}`, 'PUT', data, true);
};


export const AddCommentToTicket = async (data) => {
    return await GetApiData(`/ticket/add-comment`, 'POST', data, true);
};
