import React, { useEffect, useState } from "react";
import Statistics from "../Statistics/Statistics";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Image,
  Placeholder,
  Row,
  Table,
} from "react-bootstrap";
import LatestActivities from "views/Admin/user-management/LatestActivities";
import BreadCrumbs from "components/shared/BreadCrumbs";
import SchoolCards from "components/shared/SchoolCards";
import {
  getClassDetail,
  getClassTeachersAndStudents,
} from "services/Teacher/ClassesAndGroups.service";
import { useNavigate, useParams } from "react-router-dom";
import PageHeading from "components/shared/PageHeading";
import { getAllTimeStamps } from "services/Teacher/TimeStamp.service";
import moment from "moment";
import nodataimage from "../../../assets/nodataimage.svg";
import {
  deleteAssignments,
  getSpecificSubmissions,
} from "services/Teacher/Assignments.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faFile,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import AddStudentModal from "components/modals/AddStudentModal";
import { toast } from "react-toastify";
import { deleteStudent } from "services/Admin/UserManagement/School.service";
import ImportSheetModal from "components/modals/ImportSheetModal";
import SummaryModal from "components/modals/SummaryModal";
import { UpdateStudentStatus } from "services/Teacher/User.service";
import dropdown from "../../../assets/dropdown.svg";
import AddGroupStudentsModal from "components/modals/AddGroupStudentsModal";
import DeleteAssignmentModal from "components/modals/DeleteTeacherAssignmentModal";

const ClassesAndGroupDetailPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [students, setStudents] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [classData, setClassData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [exercises, setExercise] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showImportSheetModal, setShowImportSheetModal] = useState(false);
  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const [summaryData, setSummaryData] = useState(null);
  const [showGroupModal, setShowGroupModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [pendingStatusChange, setPendingStatusChange] = useState(null);

  const handleCloseModal = () => {
    setShowGroupModal(false);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleOpenImportSheetModal = () => setShowImportSheetModal(true);

  const handleCloseImportSheetModal = () => {
    setShowImportSheetModal(false);
  };

  useEffect(() => {
    // console.log("Summary data updated:", summaryData);
  }, [summaryData]);

  const handleShowSummaryModal = () => {
    setShowSummaryModal(true);
  };

  const handleCloseSummaryModal = () => {
    setShowSummaryModal(false);
    setSummaryData(null);
  };

  const handleShowDeleteModal = (id) => {
    setPendingStatusChange({ id, newStatus: "deleted" });
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteExercise = async (id) => {
    const toastId = toast.loading("Deleting exercise...");
    try {
      await deleteAssignments(id);
      fetchAssignments();
      toast.update(toastId, {
        render: "Assignment deleted successfully!",
        type: "success",
        isLoading: false,
        autoClose: 3000,
        closeOnClick: true,
      });
    } catch (error) {
      toast.update(toastId, {
        render: "Error deleting exercise",
        type: "error",
        isLoading: false,
        autoClose: 3000,
        closeOnClick: true,
      });
      console.error("Error deleting exercise:", error);
    } finally {
      setShowDeleteModal(false);
      setPendingStatusChange(null);
    }
  };

  const breadcrumbItems = [
    { label: "Classes & Groups", href: "/classes&groups" },
    {
      label: loading || !classData ? "Loading" : classData.title,
      active: true,
    },
  ];

  const [timeStamps, setTimeStamps] = useState([]);

  const fetchTimeStamps = async () => {
    try {
      const response = await getAllTimeStamps(params.id, "class");
      setTimeStamps(response.data.data || []);
      // console.log("Time Stamps", response.data);
    } catch (error) {
      console.error("Error fetching teachers:", error);
    }
  };

  const handleDeleteStudent = async (studentId) => {
    const toastId = toast.loading("Deleting student...");
    try {
      await deleteStudent(studentId);
      toast.update(toastId, {
        render: "Student deleted successfully!",
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
      // Refresh the student list after deletion
      fetchUsers();
    } catch (error) {
      console.error("Error deleting student:", error);
      toast.update(toastId, {
        render: "Error deleting student",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };

  const fetchUsers = async () => {
    const userType = "student";
    setLoading(true);
    try {
      const response = await getClassTeachersAndStudents(params.id, userType);
      setStudents(response.data.results || []);
      // console.log("res",response.data.results)
    } catch (error) {
      console.error("Error fetching users:", error);
      setStudents([]);
    } finally {
      setLoading(false);
    }
  };

  const handleStatusChange = async (userId, currentStatus) => {
    try {
      const newStatus = currentStatus === "active" ? "inactive" : "active";
      await UpdateStudentStatus(userId, newStatus);
      fetchUsers();
    } catch (error) {
      console.error("Error updating user status:", error);
    }
  };

  const fetchClass = async () => {
    setLoading(true);
    try {
      const response = await getClassDetail(params.id);
      setClassData(response?.data?.result || []);
    } catch (error) {
      console.error("Error fetching users:", error);
      setClassData([]);
    } finally {
      setLoading(false);
    }
  };
  const fetchTeachers = async () => {
    const userType = "teacher";
    setLoading(true);
    try {
      const response = await getClassTeachersAndStudents(params.id, userType);
      setTeachers(response.data.results || []);
      // console.log("response.data", response.data.results)
    } catch (error) {
      console.error("Error fetching users:", error);
      setTeachers([]);
    } finally {
      setLoading(false);
    }
  };
  const fetchAssignments = async () => {
    const type = "class";
    setLoading(true);
    try {
      const response = await getSpecificSubmissions(params.id, type);
      setExercise(response.data.results || []);
    } catch (error) {
      console.error("Error fetching users:", error);
      setTeachers([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClass();
    fetchUsers();
    fetchTeachers();
    fetchTimeStamps();
    fetchAssignments();
  }, []);

  const getLevelBackgroundColor = (level) => {
    switch (level) {
      case "Elementary":
        return "#C4C4C4";
      case "Intermediate":
        return "#4DA9F4";
      case "Beginner":
        return "#0FE133";
      case "Advanced":
        return "#EB3232";
      case "Expert":
        return "#408c40";
      default:
        return "#B0BEC5";
    }
  };

  return (
    <>
      <BreadCrumbs items={breadcrumbItems} />
      {loading || !classData ? (
        <div className="my-2 ">
          <Placeholder className="" as="p" animation="glow">
            <Placeholder xs={3} />
          </Placeholder>
          <Placeholder className="" as="p" animation="glow">
            <Placeholder xs={4} />
          </Placeholder>
          <hr />
        </div>
      ) : (
        <div className="my-2 ">
          <PageHeading heading={classData?.title} />
          <hr />
        </div>
      )}

      <Statistics />
      <div>
        <Row>
          <Col xs={12}>
            <LatestActivities timeStamps={timeStamps} />
          </Col>
        </Row>
      </div>

      <div>
        <div className="d-flex align-items-center justify-content-between">
          <PageHeading heading={"Students"} />
          <div>
            {classData?.type === "class" ? (
              <Dropdown>
                <Dropdown.Toggle
                  className="button-style border-0 px-3 d-flex align-items-center justify-content-center gap-1"
                  variant="success"
                  id="dropdown-basic"
                >
                  <FontAwesomeIcon icon={faPlus} />
                  <span>Add Students</span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setShowModal(true)}>
                    Add Single
                  </Dropdown.Item>
                  <Dropdown.Item onClick={handleOpenImportSheetModal}>
                    Import Sheet
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <Button
                className="button-style border-0 px-3 d-flex align-items-center justify-content-center gap-1"
                variant="success"
                id="dropdown-basic"
                onClick={() => setShowGroupModal(true)}
              >
                <FontAwesomeIcon icon={faPlus} />
                <span>Add Students</span>
              </Button>
            )}
          </div>
        </div>
        <Row>
          {students.length === 0 ? (
            <p className="my-3 px-3">No Students in the class yet. </p>
          ) : (
            students?.map((student, index) => (
              <Col xs={12} lg={4} xl={3} key={student._id || index}>
                <SchoolCards
                  id={student._id}
                  isActive={student?.status === "active" ? true : false}
                  handleToggleChange={() =>
                    handleStatusChange(student?._id, student?.status)
                  }
                  showToggle={true}
                  Name={student.fullName}
                  designation={"Student"}
                  showOptions={false}
                  image={student?.imageUrl?.url}
                  number={student.number}
                  email={student.email}
                />
              </Col>
            ))
          )}
        </Row>
      </div>
      <div>
        <div className="d-flex align-items-center justify-content-between">
          <PageHeading heading={"Teachers"} />
          <div>
            <Button
              className="button-style border-0 px-3 d-flex align-items-center justify-content-center gap-1"
              variant="success"
              id="dropdown-basic"
            >
              <FontAwesomeIcon icon={faPlus} />
              <span>Add Teachers</span>
            </Button>
          </div>
        </div>
        <Row>
          {teachers?.map((teacher, index) => (
            <Col xs={12} md={4} xl={3} key={teacher._id || index}>
              <SchoolCards
                id={teacher._id}
                Name={teacher.fullName}
                designation={"Teacher"}
                isActive={true}
                showToggle={false}
                image={teacher?.imageUrl?.url}
                showOptions={false}
                number={teacher.number}
                email={teacher.email}
              />
            </Col>
          ))}
        </Row>
      </div>
      <div>
        <div className="mt-4">
          <Table responsive="sm" style={{ overflow: "auto" }}>
            <thead>
              <tr>
                <th style={{ color: "#303972" }}>Assignment Name</th>
                <th style={{ color: "#303972" }}>Subject</th>
                <th style={{ color: "#303972" }}>Deadline</th>
                {/* <th style={{ color: "#303972" }}>Status</th> */}
                <th style={{ color: "#303972" }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                [...Array(4)].map((_, index) => (
                  <tr key={index}>
                    <td className="py-3">
                      <div
                        style={{
                          backgroundColor: "#e0e0e0",
                          height: "20px",
                          width: "80%",
                          borderRadius: "4px",
                        }}
                      ></div>
                    </td>
                    <td className="py-3">
                      <div
                        style={{
                          backgroundColor: "#e0e0e0",
                          height: "20px",
                          width: "40%",
                          borderRadius: "4px",
                        }}
                      ></div>
                    </td>
                    <td className="py-3">
                      <div
                        style={{
                          backgroundColor: "#e0e0e0",
                          height: "20px",
                          width: "40%",
                          borderRadius: "4px",
                        }}
                      ></div>
                    </td>
                    <td className="py-3">
                      <div
                        style={{
                          backgroundColor: "#e0e0e0",
                          height: "20px",
                          width: "40%",
                          borderRadius: "4px",
                        }}
                      ></div>
                    </td>
                    <td className="py-3">
                      <div
                        style={{
                          backgroundColor: "#e0e0e0",
                          height: "20px",
                          width: "40%",
                          borderRadius: "4px",
                        }}
                      ></div>
                    </td>
                  </tr>
                ))
              ) : exercises.length === 0 ? (
                <tr>
                  <td colSpan="5" className="text-center py-3">
                    <Image src={nodataimage} />
                    <h5 className="mt-2 text-secondary">
                      No Assignments found
                    </h5>
                  </td>
                </tr>
              ) : (
                exercises.map((exercise, index) => (
                  <tr key={exercise._id}>
                    <td className="py-3">
                      <div
                        //   to={`attempt/${exercise?.exercise?._id}`}
                        style={{
                          color: "#303972",
                          fontWeight: 700,
                          textDecoration: "none",
                        }}
                      >
                        {exercise.title}
                      </div>
                    </td>
                    <td className="py-3" style={{ color: "#A098AE" }}>
                      {exercise?.exercise?.subject?.title}
                    </td>
                    <td className="py-3" style={{ color: "#A098AE" }}>
                      {moment(exercise?.endDate).format("MMM D, YYYY")}
                    </td>
                    {/* <td className="py-3" style={{ color: "#A098AE" }}>
                      <span
                        style={{
                          display: "inline-block",
                          padding: "3px 10px",
                          borderRadius: "30px",
                          backgroundColor: getLevelBackgroundColor(
                            exercise?.submissionStatus
                          ),
                          color: "#fff",
                          fontSize: "14px",
                        }}
                      >
                        {exercise?.submissionStatus?.toUpperCase()}
                      </span>
                    </td> */}

                    <td className="py-3" style={{ color: "#A098AE" }}>
                      <Dropdown>
                        <Dropdown.Toggle
                          className="more bg-transparent text-black border-0 e-caret-hide rounded-0"
                          id="dropdown-basic"
                        >
                          <Card.Img
                            style={{ width: "24px", height: "24px" }}
                            variant="top"
                            src={dropdown}
                          />
                        </Dropdown.Toggle>

                        <Dropdown.Menu
                          className="border-0"
                          style={{
                            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                          }}
                        >
                          <Dropdown.Item
                            style={{ color: "black" }}
                            onClick={() =>
                              navigate("/assignments/submissions", {
                                state: { exerciseId: exercise._id },
                              })
                            }
                          >
                            <FontAwesomeIcon
                              icon={faFile}
                              style={{ fontSize: "15px" }}
                              size="20"
                              className="me-2"
                            />
                            All Submissions
                          </Dropdown.Item>

                          <Dropdown.Item
                            onClick={() =>
                              navigate(
                                `/exercise/my-exercises/${exercise.exercise._id}`
                              )
                            }
                            style={{ color: "black" }}
                          >
                            <FontAwesomeIcon
                              style={{ fontSize: "15px" }}
                              icon={faEye}
                              size="20"
                              className="me-1"
                            />
                            Show Exercise
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => handleShowDeleteModal(exercise._id)}
                            style={{ color: "red" }}
                          >
                            <FontAwesomeIcon
                              style={{ fontSize: "15px" }}
                              icon={faTrash}
                              className="me-2"
                            />
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </div>
      </div>
      <AddStudentModal
        show={showModal}
        handleClose={handleClose}
        fetchUsers={fetchUsers}
        classId={classData?._id}
      />
      <ImportSheetModal
        show={showImportSheetModal}
        handleClose={handleCloseImportSheetModal}
        fetchUsers={fetchUsers}
        classId={classData?._id}
        handleShowCredentials={handleShowSummaryModal}
        setSummaryData={setSummaryData}
      />
      <SummaryModal
        show={showSummaryModal}
        handleClose={handleCloseSummaryModal}
        summary={summaryData}
      />
      <AddGroupStudentsModal
        show={showGroupModal}
        groupId={params?.id || classData?._id}
        handleClose={handleCloseModal}
        fetchClasses={fetchUsers}
      />
      <DeleteAssignmentModal
        show={showDeleteModal}
        handleClose={handleCloseDeleteModal}
        handleConfirmDelete={() => handleDeleteExercise(pendingStatusChange.id)}
      />
    </>
  );
};

export default ClassesAndGroupDetailPage;
