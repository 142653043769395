import React from 'react'
import { Container, Image } from 'react-bootstrap'
import logo from '../../assets/thinkfirst.svg'

const ComingSoon = () => {
  return (
    <Container className='d-flex align-items-center justify-content-center flex-column gap-3 h-100'>
    <Image src={logo} width={300} />
   <div className='text-center'>
   <h4>COMING SOON!</h4>
   <h4>WORK IN PROGRESS</h4>
   </div>
    </Container>
  )
}

export default ComingSoon