import React, { useEffect, useState } from "react";
import { Button, Form, Image, InputGroup, Table } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import nodataimage from "../../../assets/nodataimage.svg";
import {
  faEye,
  faFilter,
  faSearch,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageHeading from "components/shared/PageHeading";
import { getAllSubmissions } from "services/Teacher/Assignments.service";
import moment from "moment";
const Submissions = () => {
  const location = useLocation();
  const { exerciseId } = location.state || {};
  const searchParams = new URLSearchParams(location.search);
  const initialLimit = parseInt(searchParams.get("limit"), 10) || 3;
  const initialSearchQuery = searchParams.get("search") || "";

  const [submissions, setSubmissions] = useState([]);
  const [search, setSearch] = useState(initialSearchQuery);
  const [loading, setLoading] = useState(false);

  const limit = initialLimit;

  const GetSubmissions = async (exerciseId) => {
    setLoading(true);
    try {
      const response = await getAllSubmissions(exerciseId);
      setSubmissions(response.data.results);
      console.log("response submissions", response);
    } catch (error) {
      console.error("Error fetching submissions:", error);
      setSubmissions([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (exerciseId) {
      // Fetch submissions using the exercise ID
      GetSubmissions(exerciseId);
    }
  }, [exerciseId]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const getRowBackgroundColor = (status) => {
    switch (status) {
      case "in-progress":
        return "#A7A7A7";
      case "marked":
        return "#45D921";
      case "submitted":
        return "#45D921";
      default:
        return "transparent";
    }
  };

  return (
    <>
      <div className="d-block d-lg-flex justify-content-between">
        <div>
          <PageHeading heading={"Submissions"} />
        </div>
        <div className="d-block d-lg-flex gap-3 justify-content-between">
          <div>
            <Button
              className="px-4 py-2 w-100 w-lg-auto my-lg-0 my-2"
              style={{
                color: "#858585",
                borderRadius: "30px",
                background: "white",
                border: "2px solid #858585",
                fontWeight: 600,
              }}
            >
              Sort By
              <FontAwesomeIcon icon={faFilter} className="ms-2" />
            </Button>
          </div>
          <div>
            <Form.Group
              className="mb-3 position-relative"
              controlId="formBasicPassword"
            >
              <InputGroup className="mb-3">
                <Form.Control
                  type={"text"}
                  placeholder="Search Here..."
                  className="px-3 py-2 border-right-0"
                  value={search}
                  onChange={handleSearchChange}
                  style={{
                    borderTopLeftRadius: "30px",
                    borderBottomLeftRadius: "30px",
                    borderColor: "#ea4f50",
                    borderRight: "0",
                  }}
                />
                <InputGroup.Text
                  id="basic-addon2"
                  className="bg-white "
                  style={{
                    cursor: "pointer",
                    borderTopRightRadius: "30px",
                    borderBottomRightRadius: "30px",
                    borderColor: "#ea4f50",
                    borderLeft: "0",
                  }}
                >
                  <FontAwesomeIcon icon={faSearch} />
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <Table responsive="sm" style={{ overflow: "auto" }}>
          <thead>
            <tr>
              <th style={{ color: "#303972" }}>Student Name</th>
              <th style={{ color: "#303972" }}>Submitted At</th>
              <th style={{ color: "#303972" }}>Status</th>
              <th style={{ color: "#303972" }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              [...Array(limit)].map((_, index) => (
                <tr key={index}>
                  <td className="py-3">
                    <div
                      style={{
                        backgroundColor: "#e0e0e0",
                        height: "20px",
                        width: "80%",
                        borderRadius: "4px",
                      }}
                    ></div>
                  </td>

                  <td className="py-3">
                    <div
                      style={{
                        backgroundColor: "#e0e0e0",
                        height: "20px",
                        width: "40%",
                        borderRadius: "4px",
                      }}
                    ></div>
                  </td>
                  <td className="py-3">
                    <div
                      style={{
                        backgroundColor: "#e0e0e0",
                        height: "20px",
                        width: "40%",
                        borderRadius: "4px",
                      }}
                    ></div>
                  </td>
                  <td className="py-3">
                    <div
                      style={{
                        backgroundColor: "#e0e0e0",
                        height: "20px",
                        width: "40%",
                        borderRadius: "4px",
                      }}
                    ></div>
                  </td>
                </tr>
              ))
            ) : submissions.length === 0 ? (
              <tr>
                <td colSpan="4" className="text-center py-3">
                  <Image src={nodataimage} />
                  <h5 className="text-secondary mt-2">No submissions found</h5>
                </td>
              </tr>
            ) : (
              submissions.map((exercise, index) => (
                <tr key={exercise._id}>
                  <td className="py-3">
                    <Link
                      to={`mark/${exercise?._id}`}
                      style={{
                        color: "#303972",
                        fontWeight: 700,
                        textDecoration: "none",
                      }}
                    >
                      {exercise.student.fullName}
                    </Link>
                  </td>
                  <td className="py-3" style={{ color: "#A098AE" }}>
                    {moment(exercise?.submittedAt).format("MMM D, YYYY")}
                  </td>

                  <td className="py-3 " style={{ color: "#A098AE" }}>
                    <span
                      className="text-white"
                      style={{
                        background: getRowBackgroundColor(exercise.status),
                        borderRadius: "60px",
                        padding: "5px 10px",
                      }}
                    >
                      {" "}
                      {exercise.status}
                    </span>
                  </td>
                  <td className="py-3">
                    <Link to={`mark/${exercise?._id}`}>
                      <Button className="button-style border-0 py-1 px-3">
                        {exercise.status === "submitted"
                          ? "Mark Submission"
                          : "View Submission"}
                      </Button>
                    </Link>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default Submissions;
