import { GetApiData } from "utils/http-client";

export const Assignments = async (data) => {
    return await GetApiData(`/assignment`, 'POST', data, true);
};
export const deleteAssignments = async (id) => {
    return await GetApiData(`/assignment/${id}`, 'DELETE', null, true);
};
export const getAllAssignment = async (page, limit, search, source,classId=null) => {
    const queryParams = [];

    if (page) queryParams.push(`page=${page}`);
    if (limit) queryParams.push(`limit=${limit}`);
    if (search) queryParams.push(`search=${encodeURIComponent(search)}`);
    if (source) queryParams.push(`source=${source}`);
    if (classId) queryParams.push(`classId=${classId}`);

    const queryString = queryParams.length ? `?${queryParams.join('&')}` : '';

    return await GetApiData(`/assignment${queryString}`, 'GET', null, true);
};

export const getAllSubmissions = async (id) => {
    return await GetApiData(`/submission/${id}?status=submitted`, 'GET', null, true);
};
export const getSpecificSubmissions = async (id,type) => {
    return await GetApiData(`/assignment/collective/${id}?type=${type}`, 'GET', null, true);
};

export const getLessonPlannerAssignments = async () => {
    return await GetApiData(`/assignment/lesson-planner`, 'GET', null, true);
};

export const getAssignmentDetails = async (id) => {
    return await GetApiData(`/assignment/${id}`, 'GET', null, true);
};

export const updateAssignment = async (id,data) => {
    return await GetApiData(`/assignment/${id}`, 'PUT', data, true);
};

