import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import InputComponent from 'components/shared/InputComponent';
import SelectReact from 'react-select';
import { getAllTeachersAndStudents } from 'services/Teacher/User.service';
import { CreateClass } from 'services/Teacher/Class.service';
import { toast } from 'react-toastify';

const AddClassModal = ({ show, handleClose, fetchClasses, type }) => {
  const [name, setName] = useState('');
  const [selectedTeachers, setSelectedTeachers] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const schoolId = JSON.parse(localStorage.getItem('user'))?.school;

  useEffect(() => {
    const fetchTeachers = async () => {
      try {
        const response = await getAllTeachersAndStudents(schoolId, 'teacher');
        setTeachers(response.data.users || []);
      } catch (error) {
        console.error('Error fetching teachers:', error);
      }
    };

    if (schoolId) {
      fetchTeachers();
    }
  }, [schoolId]);

  const handleSubmit = async () => {
    setIsSubmitting(true); // Disable button
    const payload = {
      title: name,
      teachers: selectedTeachers.map((teacher) => teacher.value),
    };

    const toastId = toast.loading(`Creating ${type}...`);

    try {
      const response = await CreateClass(payload,type);
      console.log(`${type} created successfully:`, response);
      handleClose();
      fetchClasses();
      toast.update(toastId, { render: `${type.charAt(0).toUpperCase() + type.slice(1)} created successfully!`, type: 'success', isLoading: false, autoClose: 3000 });
      setName('');
      setSelectedTeachers([]);
    } catch (error) {
      console.error(`Error creating ${type}:`, error);
      toast.update(toastId, { render: `Error creating ${type}`, type: 'error', isLoading: false, autoClose: 3000 });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered size="lg">
      <Modal.Header className="border-0" closeButton />
      <Modal.Body>
        <div className="my-2">
          <InputComponent
            label={`${type === 'group' ? 'Group' : 'Class'} Name`}
            placeholder={`Enter ${type === 'group' ? 'group' : 'class'} name`}
            value={name}
            setValue={setName}
          />
        </div>

        <div className="my-2">
          <Form.Label>{`Select Teachers for the ${type === 'group' ? 'Group' : 'Class'}`}</Form.Label>
          <SelectReact
            isMulti
            options={teachers.map((teacher) => ({ value: teacher._id, label: `${teacher.fullName} - ${teacher.email}` }))}
            value={selectedTeachers}
            onChange={(selected) => setSelectedTeachers(selected)}
            aria-label="Select multiple teachers"
            placeholder="Select teachers"
            className="multi-select"
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-between pt-0">
        <Button
          variant="secondary"
          className="mt-1 px-4 button-style py-2 border-0"
          style={{ background: 'linear-gradient(270deg, #272727 0%, #4B4B4B 100%)' }}
          onClick={handleClose}
          disabled={isSubmitting}
        >
          Back
        </Button>
        <Button
          variant="primary"
          className="mt-1 px-4 button-style py-2 border-0"
          onClick={handleSubmit}
          disabled={isSubmitting} // Disable during submission
        >
          Yes Create
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddClassModal;
