import React, { useEffect, Suspense, lazy } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from 'utils/AuthContext';
import Verification from 'views/authentications/Verification';
import AssignmentRoutes from 'views/students/Assignments/AssignmentRoutes';
import TeacherAssignmentRoutes from 'views/Teacher/Assignments/AssignmentRoutes';
import TeacherClassesAndGroupsRoutes from 'views/Teacher/ClassesAndGroups/ClassesAndGroupsRoutes';
import Loader from 'components/layouts/Loader';
import VocabularyRoutesTeacher from 'views/Teacher/Vocabulary/VocabularyRoutes';
import ContactRoutes from 'views/Teacher/Contact/ContactRoutes';
import ComingSoon from 'views/shared/ComingSoon';
const LessonPlanner = lazy(() => import('views/Teacher/LessonPlanner/LessonPlanner'));
const HelpCenterRoutes = lazy(() => import('views/Admin/help-center/HelpCenterRoutes'));
const ExerciseManagementRoutes = lazy(() => import('views/Admin/exercise-management/ExerciseManagementRoutes'));
const LibraryManagementRoutes = lazy(() => import('views/Admin/library-management/LibraryManagementRoutes'));
const ReportedContentRoutes = lazy(() => import('views/Admin/reported-content/ReportedContentRoutes'));
const Settings = lazy(() => import('views/Admin/settings/Settings'));
const UserManagementRoutes = lazy(() => import('views/Admin/user-management/UserManagementRoutes'));
const ForgetPassword = lazy(() => import('views/authentications/ForgetPassword'));
const Login = lazy(() => import('views/authentications/Login'));
const OTP = lazy(() => import('views/authentications/Otp'));
const Dashboard = lazy(() => import('views/Teacher/Dashboard/Dashboard'));
const Contact = lazy(() => import('views/Teacher/Contact/Contact'));
const StatisticMain = lazy(() => import('views/Teacher/Statistics/StatisticMain'));
const UsersRoutes = lazy(() => import('views/Teacher/users/UserRoutes'));
const ExercisesRoutes = lazy(() => import('views/Teacher/Exercises/ExercisesRoutes'));

const Layout = lazy(() => import('components/layouts/Layout'));
const Results = lazy(() => import('views/students/Results/Results'));
const ClassesAndGroupsRoutes = lazy(() => import('views/students/classesandgroups/ClassesAndGroupsRoutes'));
const StudentLibrary = lazy(() => import('views/students/Library/Library'));
const ExtraExercises = lazy(() => import('views/students/Exercises/ExtraExercises'));
const VocabularyRoutes = lazy(() => import('views/students/Vocabulary/VocabularyRoutes'));
const Notifications = lazy(() => import('views/students/Notifications/Notifications'));
const StudentDashboard = lazy(() => import('views/students/Dashboard/Dashboard'));
const SignIn = lazy(() => import('views/authentications/SignIn'));
const MessageManagement = lazy(() => import('views/Admin/message-management/MessageManagement'));
const NotFound = lazy(() => import('components/layouts/NoFound'));

const Navigation = () => {
  const { isVerified, loading, userRoles,user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/' && !loading || location.pathname === '/dashboard' && !loading) {
      if (isVerified) {
        if ((!user?.school || (user?.role === 'user' && !user?.class)) && (user.role!=="superUser") ) {
          navigate('/verification'); // Navigate to verification route
          return;
      }

        switch (userRoles) {
          case 'superUser':
            navigate('/user-management');
            break;
          case 'teacher':
            navigate('/dashboard');
            break;
          case 'admin':
              navigate('/dashboard');
            break;
          case 'user':
            navigate('/dashboard');
            break;
          default:
            navigate('/login');
        }
      } else {
        navigate('/login');
      }
    }
  }, [isVerified, navigate, location.pathname, loading, userRoles]);




  return (
    <Suspense fallback={<Loader />}>
      <Routes>
      {isVerified && (userRoles === 'superUser' || (user?.school && (user?.role !== 'user' || user?.class)))? (
          <Route element={<Layout />}>
            {userRoles === 'superUser' && (
              <>
                <Route path='/user-management/*' element={<UserManagementRoutes />} />
                <Route path='/message-management' element={<ComingSoon />} />
                <Route path='/exercise-management/*' element={<ExerciseManagementRoutes />} />
                <Route path='/library-management/*' element={<LibraryManagementRoutes />} />
                <Route path='/reported-content/*' element={<ReportedContentRoutes />} />
                <Route path='/settings' element={<Settings />} />
                <Route path='/help-center/*' element={<HelpCenterRoutes />} />
              </>
            )}
            {(userRoles === 'teacher' || userRoles === 'admin') && (
              <>
                <Route path='/dashboard' element={<Dashboard />} />
                <Route path='/exercise/*' element={<ExercisesRoutes />} />
                <Route path='/statistics' element={<StatisticMain />} />
                <Route path='/message-management' element={<ComingSoon />} />
                <Route path='/contact-us/*' element={<ContactRoutes />} />
                <Route path='/users/*' element={<UsersRoutes />} />
                <Route path='/classes&groups/*' element={<TeacherClassesAndGroupsRoutes />} />
                <Route path='/vocabulary/*' element={<VocabularyRoutesTeacher />} />
                <Route path='/assignments/*' element={<TeacherAssignmentRoutes />} />
                <Route path='/lesson-planner' element={<LessonPlanner/>} />
                <Route path='/settings' element={<Settings />} />
              </>
            )}
            {userRoles === 'user' && (
              <>
                <Route path='/dashboard' element={<StudentDashboard />} />
                <Route path='/notifications' element={<Notifications />} />
                {/* <Route path='/vocabulary/*' element={<VocabularyRoutes />} /> */}
                <Route path='/vocabulary' element={<ComingSoon />} />
                <Route path='/message-management' element={<ComingSoon />} />
                <Route path='/extra-exercises' element={<ExtraExercises />} />
                <Route path='/student-library' element={<ComingSoon />} />
                <Route path='/assignments/*' element={<AssignmentRoutes />} />
                <Route path='/classes&groups/*' element={<ClassesAndGroupsRoutes />} />
                <Route path='/lesson-planner' element={<LessonPlanner/>} />
                <Route path='/statistics' element={<StatisticMain />} />
                <Route path='/results' element={<Results />} />
                <Route path='/settings' element={<Settings />} />
                {/* <Route path='/contact-us' element={<Contact />} /> */}
              </>
            )}
          </Route>
        ):(isVerified && (
          <Route path='/verification' element={<Verification />} />
        )
        )}
        {!isVerified && (
          <>
            <Route path='/login' element={<Login />} />
            <Route path='/forget-password' element={<ForgetPassword />} />
            <Route path='/otp' element={<OTP />} />
            <Route path='/signup' element={<SignIn />} />
          </>
        )}
        {!loading && <Route path='*' element={<NotFound />} />}
      </Routes>
    </Suspense>
  );
};

export default Navigation;
