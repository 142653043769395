import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddStudySetWord from "components/modals/AddStudysetWord";
import AllWordsModal from "components/modals/AllWordsModal";
import PageHeading from "components/shared/PageHeading";
import React, { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  getVocabularyById,
  updateVocabularyId,
} from "services/Teacher/Vocabulary.service";
import VocabularyListReports from "views/students/Vocabulary/VocabularyListReports";

const VocabularyDetail = () => {
  const [studySetDetail, setStudySetDetail] = useState([]);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [updatedStudySetDetail, setUpdatedStudySetDetail] = useState([]);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const params = useParams();
  const id = params.id;
  const fetchStudySetDetails = async () => {
    try {
      const response = await getVocabularyById(id);
      setStudySetDetail(response?.data?.result?.words);
      setUpdatedStudySetDetail(response?.data?.result?.words);
    } catch (error) {
      console.error("Error fetching Study Set details:", error);
    }
  };

  useEffect(() => {
    fetchStudySetDetails();
  }, [id]);

  const handleAddWord = async (newWordId) => {
    const updatedWords = [...updatedStudySetDetail, newWordId];
    try {
      await updateVocabularyId(id, { words: updatedWords });
      await fetchStudySetDetails();
    } catch (error) {
      console.error("Error fetching Study Set details:", error);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center pb-4">
        <div>
          <PageHeading heading={"Vocabulary"} />
        </div>
        <div>
          <Button onClick={handleShow} className="button-style border-0 py-2">
            <FontAwesomeIcon className="pe-2" icon={faAdd} />
            Add words
          </Button>
        </div>
      </div>

      <Card className="border-0">
        <VocabularyListReports studySetDetail={updatedStudySetDetail} />
      </Card>

      <AddStudySetWord
        show={showModal}
        handleClose={handleCloseModal}
        addWordToVocabulary={handleAddWord}
      />
      <AllWordsModal
        show={show}
        handleClose={handleClose}
        addWordToVocabulary={handleAddWord}
      />
    </>
  );
};

export default VocabularyDetail;
