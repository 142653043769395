import { GetApiData } from "utils/http-client";


export const getAllClasses = async () => {
    return await GetApiData(`/class`, 'GET', null, true);
};

export const getAllGroups = async () => {
    return await GetApiData(`/class/groups/me`, 'GET', null, true);
};

export const CreateClass = async (data,type) => {
    return await GetApiData(`/class?type=${type}`, 'POST', data, true);
};

export const AddStudentsToGroup = async (data) => {
    return await GetApiData(`/class/group/students`, 'POST', data, true);
};

export const deleteClass = async (id) => {
    return await GetApiData(`/class/${id}`, 'DELETE', null, true);
};

export const deleteExerciseQuestions = async (id) => {
    return await GetApiData(`/class/${id}`, 'DELETE', null, true);
};