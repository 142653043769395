import TextArea from 'components/shared/TextArea';
import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';

const SendSchoolMail = ({ show, handleClose, email }) => {
    const [emailContent, setEmailContent] = useState("")

    return (
        <>


            <Modal show={show} onHide={handleClose} centered size={"lg"}>
                <Modal.Header className='border-0' closeButton>
                </Modal.Header>
                <Modal.Body>
                    <h6>Dispatch an email to <span style={{ color: "#df4c4e", fontWeight: "600" }}>{email}</span></h6>
                    <TextArea
                        rows={5}
                        value={emailContent}
                        onChange={(e) => setEmailContent(e.target.value)}
                    />

                </Modal.Body>
                <Modal.Footer className='border-0 justify-content-between'>
                    <Button variant="secondary" type='submit' className='mt-1 px-4 button-style py-2 border-0' style={{ background: " linear-gradient(270deg, #272727 0%, #4B4B4B 100%)" }} onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" type='submit' className='mt-1 px-4 button-style py-2 border-0' onClick={handleClose}>
                        Send
                    </Button>

                </Modal.Footer>
            </Modal>
        </>
    )
}

export default SendSchoolMail