import React, { useState } from "react";
import { Form, Button, Container, Row, Col, Image } from "react-bootstrap";
import { toast } from "react-toastify";
import InputComponent from "components/shared/InputComponent";
import { useNavigate } from "react-router-dom";
import { CreateTicketApi } from "services/Contact/HelpCenter.service";
import BreadCrumbs from "components/shared/BreadCrumbs";
import contactimg from "../../../assets/contactother.png";

const CreateTicket = () => {
  const [subject, setSubject] = useState("");
  const [type, setType] = useState("question"); // "question" or "bug"
  const [description, setDescription] = useState(""); // Description for the ticket
  const [loading, setLoading] = useState(false);
  const breadcrumbItems = [
    { label: "Help Center", href: "/contact-us" },
    {
      label: "Create Ticket",
      active: true,
    },
  ];
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!description) {
      toast.error("Description is required.");
      return;
    }

    if (!subject) {
        toast.error("Subject is required.");
        return;
      }

      if (!type) {
        toast.error("Type is required.");
        return;
      }

    const ticketData = {
      subject,
      type,
      comment: description, // Send the description as a comment in the payload
    };

    try {
      setLoading(true);
      const response = await CreateTicketApi(ticketData); // Assuming createTicket is a function to call the API
      console.log("Ticket created:", response.data);
      navigate(`contact-us/my-tickets/${response.data.result._id}`);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(`Error creating ticket: ${error.message}`);
    }
  };

  return (
    <Container fluid>
      <div className="mb-3">
        <BreadCrumbs items={breadcrumbItems} />
      </div>
      <Row>
        <Col xs={12} lg={7}>
          <Form
            onSubmit={handleSubmit}
            className="border border-secondary px-4 py-2 rounded-4"
          >
            {/* Subject */}
            <InputComponent
              label="Subject"
              value={subject}
              placeholder={"Enter a subject"}
              setValue={setSubject}
              required
              type="text"
            />

            {/* Type (question or bug) */}
            <Form.Label className="mt-2">Type</Form.Label>
            <Form.Select value={type} onChange={(e) => setType(e.target.value)}>
              <option value="">Select a type</option>
              <option value="query">Question/Query</option>
              <option value="issue">Bug/Issue</option>
            </Form.Select>

            {/* Description (Comment) */}
            <Form.Group className="mt-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                placeholder="Enter a description about your query/issue"
                as="textarea"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                rows={8}
                required
              />
            </Form.Group>

            <div className="text-end mt-4">
              <Button
                className="button-style border-0"
                type="submit"
                disabled={loading}
              >
                {loading ? "Creating Ticket..." : "Create Ticket"}
              </Button>
            </div>
          </Form>
        </Col>
        <Col xs={12} lg={5}>
          <div>
            <Image className="contact-img" src={contactimg} width={"100%"} />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default CreateTicket;
