import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  Card,
  ListGroup,
  Button,
  Container,
  Row,
  Col,
  Form,
} from "react-bootstrap";
import { toast } from "react-toastify";
import {
  AddCommentToTicket,
  GetTicketDetail,
} from "services/Contact/HelpCenter.service";
import BreadCrumbs from "components/shared/BreadCrumbs";
import PageHeading from "components/shared/PageHeading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faNoteSticky } from "@fortawesome/free-solid-svg-icons";
import CapitalizeFirst from "components/shared/CapitalizeFirst";

const TicketDetail = () => {
  const { ticketId } = useParams(); // Getting ticket ID from URL params
  const [ticket, setTicket] = useState(null);
  const [loading, setLoading] = useState(true);
  const [comment, setComment] = useState(""); // To store the comment text
  const [commenting, setCommenting] = useState(false); // To control the loading state of the comment submission

  const breadcrumbItems = [
    { label: "Help Center", href: "/contact-us" },
    { label: "My queries", href: "/contact-us/my-tickets" },
    { label: ticketId, active: true },
  ];

  const fetchTicketDetails = async () => {
    try {
      const response = await GetTicketDetail(ticketId);
      setTicket(response.data.result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Failed to load query details.");
    }
  };

  // Fetch ticket details by ID
  useEffect(() => {
    fetchTicketDetails();
  }, [ticketId]);

  // Handle comment submission
  const handleSubmitComment = async () => {
    if (comment.trim() === "") {
      toast.error("Please enter a comment.");
      return;
    }

    setCommenting(true);

    try {
      await AddCommentToTicket({
        ticketId,
        content: comment,
      });

      setComment(""); // Reset comment field
      setCommenting(false);
      toast.success("Comment added successfully!");
      fetchTicketDetails();
    } catch (error) {
      setCommenting(false);
      toast.error("Failed to add comment.");
    }
  };

  if (loading) {
    return <p>Loading query details...</p>;
  }

  if (!ticket) {
    return <p>No query found.</p>;
  }

  return (
    <Container fluid>
      <div className="mb-3">
        <BreadCrumbs items={breadcrumbItems} />
      </div>
      <PageHeading heading={"Query Detail"} />
      <Card className="mt-3">
        <Card.Body>
          <div className="d-flex align-items-center justify-content-start gap-2 mt-3 mb-4">
            <FontAwesomeIcon style={{ fontSize: "20px" }} icon={faNoteSticky} />
            <h4 className="mb-0">{ticket.subject}</h4>
            <h4 className="mb-0">({ticket._id})</h4>
          </div>

          <Row className="mt-3 border-start-0 border-end-0 border">
            <Col xs={12} lg={6} className="row py-2 align-items-center">
              <Col xs={6} lg={3}>
                <h5 className="mb-0">Status:</h5>
              </Col>
              <Col xs={9} className="fs-5">
                <CapitalizeFirst text={ticket.status} />
              </Col>
            </Col>
            <Col xs={12} lg={6} className="row py-2 align-items-center">
              <Col lg={3} xs={6}>
                <h5 className="mb-0">Type:</h5>
              </Col>
              <Col xs={9} className="fs-5">
                <CapitalizeFirst text={ticket.type} />
              </Col>
            </Col>
          </Row>

          <Row className="mb-3 border-bottom border-0 border">
            <Col xs={12} lg={6} className="row py-2 align-items-center">
              <Col lg={3} xs={12}>
                <h5 className="mb-0">Created by:</h5>
              </Col>
              <Col xs={9} className="fs-5" style={{ textOverflow: "ellipsis" }}>
                {ticket?.userId?.email}
              </Col>
            </Col>
            <Col xs={12} lg={6} className="row py-2 align-items-center">
              <Col xs={12} lg={3}>
                <h5 className="mb-0">Created At:</h5>
              </Col>
              <Col xs={9} className="fs-5">
                {new Date(ticket.createdAt).toLocaleString()}
              </Col>
            </Col>
          </Row>

          {/* Comments Section */}
          <div className="mt-4 mb-3">
            <h5>Comments</h5>
            {ticket.comments.length > 0 ? (
              ticket.comments.map((comment) => (
                <div
                  key={comment._id}
                  className="mb-2 border py-2 px-3 rounded-2"
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <strong>{comment.userId.fullName}</strong> (
                      {comment.userId.email}) said:
                    </div>
                    <div>
                      <small>
                        {new Date(comment.createdAt).toLocaleString()}
                      </small>
                    </div>
                  </div>
                  <p>{comment.content}</p>
                </div>
              ))
            ) : (
              <p>No comments yet.</p>
            )}
          </div>

          {/* Comment Textarea */}
          <div className="mt-4 mb-3">
            {ticket?.status === "closed" ? (
              <p>The issue has been marked as resolved. If you think there is a mistake, you can create another query.</p>
            ) : (
              <>
                <Form.Group controlId="commentContent">
                  <Form.Label>Reply</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    placeholder="Write your comment here..."
                  />
                </Form.Group>

                <Button
                  variant="dark"
                  onClick={handleSubmitComment}
                  disabled={commenting}
                  className="mt-2"
                >
                  {commenting ? "Submitting..." : "Submit Comment"}
                </Button>
              </>
            )}
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default TicketDetail;
