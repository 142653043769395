import React, { useEffect, useState } from "react";
import { Button, Image, Placeholder } from "react-bootstrap";
import PageHeading from "components/shared/PageHeading";
import AddClassModal from "components/modals/AddClassModal";
import nodataimage from "../../../assets/nodataimage.svg";
import { getAllClasses } from "services/Teacher/Class.service";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChalkboard, faUserGroup } from "@fortawesome/free-solid-svg-icons";

const ClassesAndGroups = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("class"); // Default type for modal
  const [classes, setClasses] = useState([]);
  const [groups, setGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const fetchClasses = async () => {
    setIsLoading(true);
    try {
      const response = await getAllClasses();
      setClasses(response?.data?.result?.classes?.data || []);
      setGroups(response?.data?.result?.groups?.data || []);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching classes and groups:", error);
    }
  };

  useEffect(() => {
    fetchClasses();
  }, []);

  return (
    <>
      <PageHeading heading={"Classes & Groups"} />
      <div>
        {/* Classes Section */}
        <div className="d-flex justify-content-between mt-4">
          <div>
            <PageHeading heading={"Classes"} />
          </div>
          <div>
            <Button
              className="button-style border-0 py-2 px-3"
              onClick={() => {
                setShowModal(true);
                setModalType("class"); // Set modal type to "class"
              }}
            >
              Create new class
            </Button>
          </div>
        </div>
        {isLoading ? (
          <div>
            {[...Array(3)].map((_, idx) => (
              <div key={idx} className="text-decoration-none border-bottom">
                <Placeholder as="p" animation="glow">
                  <Placeholder xs={2} />
                  <Placeholder xs={4} />
                </Placeholder>
                <Placeholder as="p" animation="glow">
                  <Placeholder xs={3} />
                </Placeholder>
              </div>
            ))}
          </div>
        ) : classes?.length === 0 ? (
          <div className="text-center py-3">
            <Image src={nodataimage} />
            <h5 className="mt-2 text-secondary">No classes created yet</h5>
          </div>
        ) : (
          classes.map((classItem) => (
            <div
              onClick={() => navigate(classItem?._id)}
              key={classItem?._id}
              className="text-decoration-none"
              style={{ cursor: "pointer" }}
            >
              <div className="py-0 custom-item text-wrap d-flex justify-content-between align-items-center border-bottom mb-2">
                <div className="d-flex gap-3 my-3">
                  <div
                  className="bg-dark d-flex align-items-center justify-content-center"
                    style={{
                      width:"40px",
                      height:"40px",
                      borderRadius: "60px",
                    }}
                  >
                <FontAwesomeIcon icon={faChalkboard} style={{fontSize:"20px",color:"white"}} />
                  </div>
                  <h6
                    className="m-0 my-auto"
                    style={{ color: "#303972", fontWeight: "700" }}
                  >
                    {classItem?.title}
                  </h6>
                </div>
              </div>
            </div>
          ))
        )}

        {/* Groups Section */}
        <div className="d-flex justify-content-between mt-4">
          <div>
            <PageHeading heading={"Groups"} />
          </div>
          <div>
            <Button
              className="button-style border-0 py-2 px-3"
              onClick={() => {
                setShowModal(true);
                setModalType("group"); // Set modal type to "group"
              }}
            >
              Create new group
            </Button>
          </div>
        </div>
        {isLoading ? (
          <div>
            {[...Array(3)].map((_, idx) => (
              <div key={idx} className="text-decoration-none border-bottom">
                <Placeholder as="p" animation="glow">
                  <Placeholder xs={2} />
                  <Placeholder xs={4} />
                </Placeholder>
                <Placeholder as="p" animation="glow">
                  <Placeholder xs={3} />
                </Placeholder>
              </div>
            ))}
          </div>
        ) : groups?.length === 0 ? (
          <div className="text-center py-3">
            <Image src={nodataimage} />
            <h5 className="mt-2 text-secondary">No groups created yet</h5>
          </div>
        ) : (
          groups.map((groupItem) => (
            <div
              onClick={() => navigate(groupItem?._id)}
              key={groupItem?._id}
              className="text-decoration-none"
              style={{ cursor: "pointer" }}
            >
              <div className="py-0 custom-item text-wrap d-flex justify-content-between align-items-center border-bottom mb-2">
                <div className="d-flex gap-3 my-3">
                <div
                  className="bg-dark d-flex align-items-center justify-content-center"
                    style={{
                      width:"40px",
                      height:"40px",
                      borderRadius: "60px",
                    }}
                  >
                <FontAwesomeIcon icon={faUserGroup} style={{fontSize:"17px",color:"white"}} />
                  </div>
                  <h6
                    className="m-0 my-auto"
                    style={{ color: "#303972", fontWeight: "700" }}
                  >
                    {groupItem?.title}
                  </h6>
                </div>
              </div>
            </div>
          ))
        )}
      </div>

      {/* Add Class or Group Modal */}
      <AddClassModal
        show={showModal}
        handleClose={handleCloseModal}
        fetchClasses={fetchClasses}
        type={modalType} // Pass the type dynamically
      />
    </>
  );
};

export default ClassesAndGroups;
