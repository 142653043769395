import React from 'react';
import { Image } from 'react-bootstrap';
import spinner from "../../assets/loader.gif"
const Loader = () => {
    return (
        <div className="d-flex justify-content-center align-items-center position-fixed w-100 h-100 top-0 start-0 bg-light">
            <Image src={spinner} alt="Loading..." className="spinner-img" />
        </div>
    );
};

export default Loader;
