import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputComponent from 'components/shared/InputComponent';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { addStudentToClass } from 'services/Teacher/AddUser.service';

const AddStudentModal = ({ show, handleClose, fetchUsers, classId }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');

    const handleCloseModal = () => {
        setName('');
        setEmail('');
        setPhone('');
        setPassword('');
        handleClose();
    };

    const handleAddStudent = async () => {
        const studentData = {
            fullName: name,
            email,
            phone,
            password,
            classId
        }

        if (!name || !email || !phone || !password) {
            toast.error('Please fill in all the fields.');
            return;
        }

        const toastId = toast.loading('Adding student...');

        try {
            await addStudentToClass(studentData);
            toast.update(toastId, { render: 'Student added successfully!', type: 'success', isLoading: false, autoClose: 3000 });
            handleClose();
            fetchUsers();
        } catch (error) {
            console.error('Error adding student:', error);
            toast.update(toastId, { render: error?.response?.data?.message || 'Error adding student', type: 'error', isLoading: false, autoClose: 3000 });
        }
    };

    return (
        <Modal show={show} onHide={handleCloseModal} centered size={"md"}>
            <Modal.Header className='border-0' closeButton>
            </Modal.Header>
            <Modal.Body>
                <div className="text-center">
                    <FontAwesomeIcon className='p-3' icon={faAdd} style={{ fontSize: "40px", color: "#e15354", border: "3px solid #e15354 ", borderRadius: "60px", width: "40px", height: "40px" }} />
                    <h3 className='mt-1'> Add Student</h3>
                </div>
                <div>
                    <InputComponent
                        label="Student Name"
                        placeholder="Enter Student name"
                        value={name}
                        setValue={setName}
                    />
                </div>
                <div>
                    <InputComponent
                        type='email'
                        label="Student Email"
                        placeholder="Enter Student email"
                        value={email}
                        setValue={setEmail}
                    />
                </div>
                <div>
                    <InputComponent
                        type='number'
                        label="Phone Number"
                        placeholder="Enter Phone Number"
                        value={phone}
                        setValue={setPhone}
                    />
                </div>
                <div>
                    <InputComponent
                        label="Password"
                        placeholder="Enter Password"
                        type="password"
                        value={password}
                        setValue={setPassword}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer className='border-0 justify-content-between pt-0'>
                <Button
                    variant="secondary"
                    type='button'
                    className='mt-1 px-4 button-style py-2 border-0'
                    style={{ background: "linear-gradient(270deg, #272727 0%, #4B4B4B 100%)" }}
                    onClick={handleCloseModal}
                >
                    Back
                </Button>
                <Button
                    variant="primary"
                    type='button'
                    className='mt-1 px-4 button-style py-2 border-0'
                    onClick={handleAddStudent}
                >
                    Add Student
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddStudentModal;
