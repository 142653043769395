import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ClassesAndGroups from './ClassesAndGroups'
import ClassesAndGroupDetailPage from './ClassesAndGroupDetailPage'

const TeacherClassesAndGroupsRoutes = () => {
    return (
        <>
            <Routes>
                <Route
                    path='/'
                    element={<ClassesAndGroups />}
                />
                <Route
                    path='/:id'
                    element={<ClassesAndGroupDetailPage />}
                />


            </Routes>
        </>
    )
}

export default TeacherClassesAndGroupsRoutes