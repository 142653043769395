import { GetApiData } from "utils/http-client";


export const getClassTeachersAndStudents = async (id, role="student") => {
    return await GetApiData(`/class/members/${role}/${id}`, 'GET', null, true);
};

export const getClassDetail = async (id) => {
    return await GetApiData(`/class/${id}`, 'GET', null, true);
};

export const importStudentSheet = async (data) => {
    return await GetApiData(`/class/students/import`, 'POST', data, true);
};

export const createBulkStudents = async (data) => {
    return await GetApiData(`/class/students/create`, 'POST', data, true);
};



