import React, { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";

const SummaryModal = ({ show, handleClose, summary }) => {
 console.log("files 2   ");
  return (
    <Modal show={show} onHide={handleClose} centered size={"md"} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Import Summary</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-4">
          <p className="fw-bold">Summary of Import:</p>
          <ul>
            <li>{summary?.report?.successfullyAdded} students were successfully added.</li>
            <li>{summary?.report?.ignoredStudents} students were ignored because they already exist in a class.</li>
          </ul>
          
        </div>
        {summary?.fileUrl && (
            <>
            <p className="text-danger">
            Please store the credentials file safely as it will not be available for download again.
          </p>
            <div className="d-flex align-items-center gap-2">
            <Button
              variant="primary"
              className="button-62 border-0 bg-dark d-flex align-items-center gap-2"
              href={summary?.fileUrl}
              download
            >
              <FontAwesomeIcon icon={faFileDownload} />
              <span>Download Credentials File</span>
            </Button>
          </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} className="button-62">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SummaryModal;